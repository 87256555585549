import { Button, Input, SageClassnames, Tag, Frame } from '@kajabi/sage-react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { localizedPriceFromMinorUnit } from '../util';

export const Coupon = ({ isCouponLoading, couponError }) => {
  const [showCouponField, setShowCouponField] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const { t } = useTranslation();
  const { getValues, setValue, watch } = useFormContext();
  const { price } = getValues();
  const { currency, exponent } = price;
  const appliedCoupon = watch('coupon');

  const shouldDisableApplyButton = () => couponCode.length === 0 || isCouponLoading;

  const handleChange = () => {
    setShowCouponField(true);
  };

  const handleCouponCodeChange = (event) => {
    setCouponCode(event.target.value.trim());
  };

  const applyCoupon = () => {
    setValue('couponCode', couponCode);
  };

  const clearCoupon = () => {
    setValue('couponCode', null);
    setValue('coupon', null);
    setShowCouponField(false);
    setCouponCode('');
  };
  const Divider = () => <div className="price-summary__divider" />;
  return (
    <>
      <Divider />
      {appliedCoupon && (
        <>
          <Frame
            direction={Frame.DIRECTIONS.HORIZONTAL}
            gap={Frame.GAPS.SM}
            align={Frame.ALIGNMENTS.CENTER_SPREAD}
            className="price-detail"
          >
            <span className="summary-item__title-text">
              <Tag
                data-testid="coupon-clear-button"
                dismissAttributes={{
                  onClick: () => clearCoupon(),
                }}
                showDismiss
                value={appliedCoupon.couponCode}
              />
            </span>
            <p
              className={`summary-item__price ${SageClassnames.TYPE.BODY_SEMI} ${SageClassnames.TYPE_COLORS.CHARCOAL_200}`}
            >
              {localizedPriceFromMinorUnit(currency, -1 * appliedCoupon.discountAmount, exponent)}
            </p>
          </Frame>
          <Frame
            direction={Frame.DIRECTIONS.HORIZONTAL}
            gap={Frame.GAPS.SM}
            className="price-detail"
          >
            <p className="subscription-interval">{appliedCoupon.couponDescription}</p>
          </Frame>
        </>
      )}

      {!appliedCoupon && (
        <>
          {showCouponField ? (
            <div className="coupon__container">
              <Input
                data-testid="coupon-input"
                id="coupon-input"
                placeholder={t('coupon.input_placeholder')}
                value={couponCode}
                onChange={handleCouponCodeChange}
                onKeyPress={(e) => e.key === 'Enter' && applyCoupon()}
                hasError={couponError}
                message={couponError === '' ? null : t(`coupon.${couponError}`)}
              />
              <span className="coupon__apply-button">
                <Button
                  data-testid="coupon-apply-button"
                  disabled={shouldDisableApplyButton()}
                  onClick={applyCoupon}
                  subtle
                >
                  {t('coupon.apply_button')}
                </Button>
              </span>
            </div>
          ) : (
            <Button data-testid="coupon-add-button" icon="coupon" onClick={handleChange} subtle>
              {t('coupon.add_button')}
            </Button>
          )}
        </>
      )}
    </>
  );
};

Coupon.propTypes = {
  isCouponLoading: PropTypes.bool,
  couponError: PropTypes.string,
};

Coupon.defaultProps = {
  isCouponLoading: false,
  couponError: '',
};

export default Coupon;
