/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useCheckout from 'apps/commerce/common/checkout/hooks/useCheckout';
import useToast from 'apps/commerce/common/checkout/hooks/useToast';
import { checkoutHandler } from './checkoutHandler';
import {
  getRequest,
  localizedPrice,
  localizedPriceFromMinorUnit,
  majorUnitAmount,
  minorUnitAmount,
} from '../util';
import { OfferStatus } from '../constants';
import { trackEvent } from '../googleAnalytics';

declare global {
  interface Window {
    Kajabi: any;
  }
}

// TODO: This file was originally created in JS, and was converted to TS later
// We ought to use types rather than `any` where possible
export default function usePopupCheckout(offerToken: string, enableModal: boolean) {
  const { setValue, reset, watch, setError, getValues } = useFormContext();
  const { t } = useTranslation();
  const [data, setData] = useState(null) as any;
  const [modalActive, setModalActive] = useState(false);
  const [apiDataLoaded, setApiDataLoaded] = useState(false);
  const [offerDataLoaded, setOfferDataLoaded] = useState(false);
  const [isCouponLoading, setIsCouponLoading] = useState(false);
  const [couponError, setCouponError] = useState('');
  // @ts-ignore -- TODO: add right type here
  const { showToast } = useToast();
  useCheckout();

  const isPaymentInProgress = watch('isPaymentInProgress');
  const priceOverrideRequired = watch('priceOverrideRequired');
  const priceOverride = watch('priceOverride');
  const coupon = watch('coupon');
  const couponCode = watch('couponCode');
  const orderBumpActive = watch('orderBumpActive');
  const quantity = watch('quantity');
  const taxLoading = watch('taxLoading');

  const siteUserSignedIn = window.Kajabi?.currentSiteUser?.type === 'User';

  const handleHttpError = useCallback((err) => {
    const status = err.response?.status;
    const { code } = err.response?.data || {};
    setValue('isPaymentInProgress', false);
    if (status === 404) {
      showToast({ title: t('messages.offer_not_found'), type: 'danger' });
    } else if (status === 401) {
      showToast({ title: t('messages.token_expiry'), type: 'danger' });
    } else if (status === 422 && code === 'offer_quantity_limit_exceeded') {
      showToast({ title: t('messages.offer_quantity_limit_exceeded'), type: 'danger' });
    } else {
      showToast({ title: t('messages.something_went_wrong'), type: 'danger' });
    }
    handleHideModal();
  }, []);

  useEffect(() => {
    if (!modalActive) return;
    getRequest(`/api/offers/${offerToken}/checkout`)
      .then((response) => {
        const responseData = response.data.data;
        if (response.status === 200) {
          setValuesFromResponse(responseData);
          trackEvent('view_cart', responseData);
        }
      })
      .catch((err) => handleHttpError(err));
  }, [modalActive]);

  useEffect(() => {
    if (!modalActive || !couponCode) return;
    const getCouponEndpoint = `/api/offers/${offerToken}/checkout/coupon/${couponCode}?quantity=${quantity}`;
    setIsCouponLoading(true);
    getRequest(getCouponEndpoint)
      .then((response) => {
        if (response.status === 200) {
          const { coupon } = response.data.data;
          setValue('coupon', coupon);
        }
      })
      .catch((error) => {
        if (error.response?.data?.code === 'coupon_expired') {
          setCouponError('expired');
        } else {
          setCouponError('invalid');
        }
      })
      .finally(() => {
        setIsCouponLoading(false);
      });
  }, [modalActive, couponCode, quantity]);

  const setValuesFromResponse = (responseData: any) => {
    const { offer } = responseData;
    setData(responseData);
    // TODO: update the response to return checkout settings that aren't part of the "offer" object
    setValue('checkoutSettings', {
      paymentMethods: offer.paymentMethods,
      additionalPaymentMethods: offer.additionalPaymentMethods,
      additionalPaymentMethodsPriceLimits: offer.additionalPaymentMethodsPriceLimits,
      nameRequired: offer.nameRequired,
      phoneNumberRequired: offer.phoneNumberRequired,
      addressRequired: offer.addressRequired,
      stripePublishableKey: offer.stripePublishableKey,
      stripeAccountId: offer.stripeAccountId,
      vendorAccountId: offer.vendorAccountId,
      checkoutPageColor: offer.checkoutPageColor,
      checkoutTextOverride: offer.checkoutTextOverride,
      serviceAgreementRequired: offer.serviceAgreementRequired,
      serviceAgreementCustomText: offer.serviceAgreementCustomText,
      showOptIn: responseData.showOptIn,
      optInDefaultValue: responseData.optInDefaultValue,
      optInTextOverride: responseData.optInTextOverride,
      customFields: responseData.offer.customFields || [],
      taxable: responseData.offer.taxable,
      taxInclusive: responseData.offer.taxInclusive,
      collectTaxId: responseData.offer.collectTaxId,
      countriesToValidateTaxId: responseData.offer.countriesToValidateTaxId,
    });

    setValue('offer', offer);
    setValue('paymentMethods', offer.paymentMethods);
    if (offer.checkoutQuantityEnabled) {
      setValue('quantity', 1);
    }
    if (offer.quantityRemaining) {
      setValue('quantityRemaining', offer.quantityRemaining);
    }
    setValue('site', responseData.site);
    setValue('price', responseData.price);
    if (responseData.orderBump) {
      setValue('orderBump', responseData.orderBump);
    }

    setValue('quantityLimitDisplayThreshold', responseData.offer.quantityLimitDisplayThreshold);
    setValue('priceOverrideRequired', responseData.price.priceStrategy === 'pay_what_you_want');
    setValue(
      'priceOverride',
      majorUnitAmount(responseData.price.amount, responseData.price.exponent),
    );
    setValue('offerPurchase', responseData.offerPurchase);

    setValue('activeFeatures', responseData.features); // activeFeatures = []
    if (responseData.member) setValue('member', responseData.member);
    setValue('memberLoaded', true);

    setApiDataLoaded(true);
    // If the offer doesn't need stripe then go ahead and set the offer data as loaded.
    if (!offer.stripePublishableKey) {
      setOfferDataLoaded(true);
    }
  };

  const checkoutLoaded = watch('checkoutLoaded');
  const paymentMethods = watch('paymentMethods');
  useEffect(() => {
    if (!checkoutLoaded) return;

    const isPaypalOffer = paymentMethods?.length === 1 && paymentMethods?.includes('paypal');
    if (isPaypalOffer) {
      setValue('paymentProvider', 'external_paypal');
    }
  }, [checkoutLoaded, paymentMethods]);

  // Stripe is initialized by useCheckout, once it's done set offer data as loaded.
  const stripe = watch('stripe');
  useEffect(() => {
    if (apiDataLoaded && stripe) {
      setOfferDataLoaded(true);
    }
  }, [apiDataLoaded, stripe]);

  const handleShowModal = useCallback(() => {
    setModalActive(enableModal);
  }, [enableModal]);

  const handleHideModal = useCallback(() => {
    if (!isPaymentInProgress) {
      trackEvent('abandoned_checkout', data);
      setModalActive(false);
      setOfferDataLoaded(false);
      reset({});
    }
  }, [reset, isPaymentInProgress, data]);

  const showSaveCardCheckbox = watch('showSaveCardCheckbox');
  const saveCardDefaultValue = watch('saveCardDefaultValue');
  const dueNowPriceInCents = watch('dueNowPriceInCents');
  const amountPriceInCents = watch('amountPriceInCents');
  const priceOverrideInCents = watch('priceOverrideInCents');

  // Update pop up state when the coupon is applied / cleared
  useEffect(() => {
    if (!data) return;
    const { price } = data;
    // Pay what you want is not required when a coupon is applied and a coupon change will
    // reset the pay what you want amount
    if (coupon) {
      setValue('priceOverrideRequired', false);
    } else {
      setValue('priceOverrideRequired', price.priceStrategy === 'pay_what_you_want');
    }
    setValue('priceOverride', majorUnitAmount(price.amount, price.exponent), {
      shouldValidate: true,
    });

    // An order bump will be deactivated when a gift coupon is applied.
    if (coupon?.giftCoupon) {
      setValue('orderBumpActive', false);
    }
  }, [data, coupon]);

  const isOfferUnavailable = useMemo(() => {
    if (!data) return false;
    return data.offer.status !== OfferStatus.AVAILABLE;
  }, [data]);

  const aboveQuantityLimit = useMemo(() => {
    if (!data) return false;
    return data.offer.quantityRemaining && quantity > data.offer.quantityRemaining;
  }, [data, quantity]);

  useEffect(() => {
    if (aboveQuantityLimit) {
      showToast({ title: t('form.quantity_limit_reached'), type: 'danger' });
    }
    setValue('aboveQuantityLimit', aboveQuantityLimit);
  }, [aboveQuantityLimit]);

  // This is the base offer price
  const displayPrice = useMemo(() => {
    if (!data) return '';
    // Prioritize the price text override when set
    if (data.offer.priceTextOverride) return data.offer.priceTextOverride;

    // Get the base price
    const { price } = data;
    const priceAmount = price.subscriptionPlanAttributes
      ? price.subscriptionPlanAttributes.paymentAmount
      : price.amount;

    // Special text when base price is free (but not a PWYW)
    if (priceAmount === 0 && !priceOverrideRequired) return t('form.free');

    // Format the base price
    const formattedPrice = localizedPriceFromMinorUnit(price.currency, priceAmount, price.exponent);
    return priceOverrideRequired ? `${formattedPrice} (${t('form.or_more')})` : formattedPrice;
  }, [data, priceOverrideRequired, t]);

  // This is the PWYW price
  const displayOverridePrice = useMemo(() => {
    if (!data) return null;
    if (!priceOverrideRequired) return null;
    const { price } = data;
    return localizedPrice(price.currency, priceOverride);
  }, [data, priceOverride]);

  const isOfferFree = useMemo(() => {
    if (!data) return false;
    // A gift coupon makes the offer free
    if (coupon) return coupon.giftCoupon;

    // For a non-subscription offer, $0 due now means free.
    const { price } = data;
    if (!price.subscriptionPlanAttributes) return dueNowPriceInCents === 0;

    // For a subscription offer, $0 payments + $0 setup fee means free.
    const { paymentAmount, setupFee } = price.subscriptionPlanAttributes;
    return paymentAmount + setupFee === 0;
  }, [data, dueNowPriceInCents, coupon]);

  const redirectToFlow = (response: any) => {
    if (response.data.data.redirectUrl) {
      window.location.replace(response.data.data.redirectUrl);
    }
  };

  // Generate functions for form submission
  const { handleSubmit } = useFormContext();
  const submitForm = handleSubmit(() =>
    checkoutHandler(
      setValue,
      getValues,
      setError,
      isOfferFree,
      showToast,
      t,
      data,
      redirectToFlow,
      handleHttpError,
      showSaveCardCheckbox,
    ),
  );

  return {
    offerDataLoaded,
    modalActive,
    handleShowModal,
    handleHideModal,
    displayPrice,
    displayOverridePrice,
    dueNowPriceInCents,
    amountPriceInCents,
    isOfferUnavailable,
    isOfferFree,
    submitForm,
    showSaveCardCheckbox,
    saveCardDefaultValue,
    siteUserSignedIn,
    priceOverrideInCents,
    taxLoading,
    isCouponLoading,
    couponError,
    aboveQuantityLimit,
  };
}
