import { useFormContext } from 'react-hook-form';
import React from 'react';
import { Frame } from '@kajabi/sage-react';

export const PaymentSummaryText = () => {
  const { getValues } = useFormContext();
  const { price, coupon } = getValues();
  const { subscriptionPlanAttributes } = price;
  const paymentScheduleDescription = coupon?.paymentScheduleDescription
    || subscriptionPlanAttributes?.paymentScheduleDescription;

  if (!paymentScheduleDescription) {
    return null;
  }

  return (
    <Frame
      data-testid="payment-summary-text"
      className="payment-summary-text"
    >
      {paymentScheduleDescription}
    </Frame>
  );
};
