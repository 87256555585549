import { Input } from '@kajabi/sage-react';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { decimalSeparator, getSiteLocaleWithDash } from '../util';


const PriceOverrideInput = ({ displayPrice, disabled }) => {
  // use the browser's locale to determine the decimal separator


  const { t } = useTranslation();
  const {
    getValues,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const { price } = getValues();
  const priceOverride = watch('priceOverride');
  // determine the currency symbol for the currency
  // all of our supported currencies are 1 character
  const currencySymbol = useCallback(() => {
    const str = new Intl.NumberFormat(getSiteLocaleWithDash(), {
      style: 'currency',
      currency: price.currency
    }).formatToParts(1)
      .filter(part => part.type === 'currency')[0]?.value;
    if (str.includes('$')) return '$';
    return str;
  }, [price]);


  const isAmount = useCallback((input) => {
    const pattern = price.exponent > 0
      ? `^[0-9]{0,6}(?:[${decimalSeparator()}][0-9]{0,${price.exponent}})?$`
      : '^[0-9]{0,6}$';
    const amountFormat = new RegExp(pattern);
    return amountFormat.test(input);
  }, [price.exponent]);


  return (
    <div className="pwyw-container">
      <div className="input">
        <p className="label">{`${t('form.name_your_price')}`}</p>
        <p className="minimum">{`${t('form.minimum')}: ${displayPrice}`}</p>
        <Input
          id="price-override"
          data-testid="price-override"
          inputMode="numeric"
          value={priceOverride}
          prefix={currencySymbol()}
          disabled={disabled}
          hasError={!!errors?.priceOverride}
          message={t(errors?.priceOverride?.message)}
          onChange={(e) => {
            const newValue = e.target.value;
            if (isAmount(newValue)) {
              setValue('priceOverride', newValue, { shouldValidate: true });
            }
          }}
        />
      </div>
    </div>
  );
};

PriceOverrideInput.propTypes = {
  displayPrice: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default PriceOverrideInput;
