/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { SageBreakpointProvider, SageTokens } from '@kajabi/sage-react';
import CheckoutStripeElements from 'apps/commerce/common/checkout/components/CheckoutStripeElements';
import usePopupCheckout from './hooks/usePopupCheckout';
import PopupCheckoutModal from './PopupCheckoutModal';

// Need to import i18n to make sure it's loaded
// eslint-disable-next-line no-unused-vars
import i18next from './i18n';
import './styles/style.scss';

function PopupCheckout({ el, offerToken, enableModal }) {
  const {
    offerDataLoaded,
    modalActive,
    handleShowModal,
    handleHideModal,
    displayPrice,
    displayOverridePrice,
    dueNowPriceInCents,
    amountPriceInCents,
    showSaveCardCheckbox,
    saveCardDefaultValue,
    isOfferUnavailable,
    isOfferFree,
    submitForm,
    siteUserSignedIn,
    taxLoading,
    isCouponLoading,
    couponError,
    aboveQuantityLimit,
  } = usePopupCheckout(offerToken, enableModal);
  const shouldRenderModal = modalActive && offerDataLoaded;
  const triggerElement = React.useMemo(
    () => (
      <span
        tabIndex="0"
        role="button"
        aria-label="Open"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: el.toString() }}
        onClick={handleShowModal}
        // using onMouseUp to avoid the stopPropagation in the exit and two step popup modals
        // we'll need to remove this in the theme if we ever want to strictly use onClick
        // see exit_pop.js and two_step_popup.js in the encore site theme for more info
        onMouseUp={handleShowModal}
        onKeyDown={null}
      />
    ),
    [el, handleShowModal],
  );
  const { getValues } = useFormContext();
  const {
    stripe,
    attemptReloadWithCardsOnly,
    currency,
    paymentMethods,
    additionalPaymentMethods,
    vendorAccountId,
  } = getValues();

  return (
    <>
      {shouldRenderModal &&
        ReactDOM.createPortal(
          <CheckoutStripeElements
            attemptReloadWithCardsOnly={attemptReloadWithCardsOnly}
            stripe={stripe}
            amountPriceInCents={amountPriceInCents}
            currency={currency}
            paymentMethods={paymentMethods}
            additionalPaymentMethods={additionalPaymentMethods}
            vendorAccountId={vendorAccountId}
          >
            <SageBreakpointProvider queries={SageTokens.BREAKPOINT_QUERIES}>
              <PopupCheckoutModal
                className="popup-checkout-modal"
                active={modalActive}
                displayPrice={displayPrice}
                displayOverridePrice={displayOverridePrice}
                dueNowPriceInCents={dueNowPriceInCents}
                isOfferUnavailable={isOfferUnavailable}
                showSaveCardCheckbox={showSaveCardCheckbox}
                saveCardDefaultValue={saveCardDefaultValue}
                isOfferFree={isOfferFree}
                submitForm={submitForm}
                handleHideModal={handleHideModal}
                siteUserSignedIn={siteUserSignedIn}
                taxLoading={taxLoading}
                isCouponLoading={isCouponLoading}
                couponError={couponError}
                aboveQuantityLimit={aboveQuantityLimit}
              />
            </SageBreakpointProvider>
          </CheckoutStripeElements>,
          document.body,
        )}
      {triggerElement}
    </>
  );
}

PopupCheckout.propTypes = {
  el: PropTypes.string.isRequired,
  offerToken: PropTypes.string.isRequired,
  enableModal: PropTypes.bool,
};

PopupCheckout.defaultProps = {
  enableModal: true,
};

export default PopupCheckout;
