import React from 'react';
import { Button, Input, Frame, SageClassnames, Tooltip } from '@kajabi/sage-react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const QuantitySelector = () => {
  const { setValue, getValues, watch } = useFormContext();
  const { quantityRemaining } = getValues();
  const quantity = watch('quantity');
  const { t } = useTranslation();

  // Show the offers remaining message if the quantity remaining is less than the quantity selected
  const handleQuantityChange = React.useCallback(
    (value) => {
      const newValue = value < 1 ? 1 : parseInt(value, 10);
      setValue('quantity', Number(newValue));
    },
    [setValue],
  );

  const Heading = () => (
    <div className="quantity-selector__heading">
      <span className={`${SageClassnames.TYPE.HEADING_5}`}>{t('form.quantity')}</span>
    </div>
  );
  const atMaxQuantity = quantity === quantityRemaining;
  const WrapperComponent = atMaxQuantity ? Tooltip : React.Fragment;
  const ToolTipProps = atMaxQuantity
    ? { content: t('form.quantity_limit_reached'), position: 'top' }
    : {};

  return (
    <Frame
      direction={Frame.DIRECTIONS.HORIZONTAL}
      align={Frame.ALIGNMENTS.CENTER_SPREAD}
      gap={Frame.GAPS.XS}
      className="price-display"
    >
      <Heading />
      <WrapperComponent {...ToolTipProps}>
        <Frame
          width="auto"
          direction={Frame.DIRECTIONS.HORIZONTAL}
          gap={Frame.GAPS.NONE}
          className="price-detail quantity-selector"
          data-testid="quantity-selector"
        >
          <Button
            small
            color="secondary"
            icon="horizontal-line"
            iconOnly={true}
            disabled={quantity <= 1}
            onClick={() => handleQuantityChange(Number(quantity) - 1)}
            data-testid="decrement-quantity"
          />
          <Input
            id="quantity"
            data-testid="quantity"
            onChange={(e) => handleQuantityChange(e.target.value)}
            type="number"
            min={1}
            value={quantity}
          />
          <Button
            small
            color="secondary"
            icon="add"
            iconOnly={true}
            onClick={() => handleQuantityChange(Number(quantity) + 1)}
            disabled={atMaxQuantity}
            data-testid="increment-quantity"
          />
        </Frame>
      </WrapperComponent>
    </Frame>
  );
};

export default QuantitySelector;
