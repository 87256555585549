/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Banner,
  Button,
  Modal,
  Tooltip,
  SageClassnames,
  useSageBreakpoint,
} from '@kajabi/sage-react';
import { useElements } from '@stripe/react-stripe-js';
import { Helmet } from 'react-helmet';

import useCheckoutSettings from 'apps/commerce/common/checkout/hooks/useCheckoutSettings';
import AddressInput from 'apps/commerce/common/checkout/inputs/AddressInput';
import CustomFields from 'apps/commerce/common/checkout/components/CustomFields/CustomFields';
import EmailInput from 'apps/commerce/common/checkout/inputs/EmailInput';
import NameInput from 'apps/commerce/common/checkout/inputs/NameInput';
import OptInCheckbox from 'apps/commerce/common/checkout/inputs/OptInCheckbox';
import PayButton from 'apps/commerce/common/checkout/components/PayButton';
import PaymentDetails from 'apps/commerce/common/checkout/inputs/PaymentDetails';
import PhoneInput from 'apps/commerce/common/checkout/inputs/PhoneInput';
import SavedCard from 'apps/commerce/common/checkout/inputs/SavedCard';
import SaveCardCheckbox from 'apps/commerce/common/checkout/inputs/SaveCardCheckbox';
import SecureCheckout from 'apps/commerce/common/checkout/components/SecureCheckout';
import ServiceAgreement from 'apps/commerce/common/checkout/components/ServiceAgreement';
import { CloseIcon } from './images/close';
import TaxIdInput from './inputs/TaxIdInput';
import PayPalButton from './inputs/PayPalButton';
import PriceOverrideInput from './inputs/PriceOverrideInput';
import { PriceDisplay } from './components/PriceDisplay';
import { PaymentSummaryText } from './components/PaymentSummaryText';
import { PriceSummary } from './components/PriceSummary';
import { localizedDateWithYear, minorUnitAmount } from './util';
import QuantitySelector from './components/QuantitySelector';

function PopupCheckoutModal({
  active,
  displayPrice,
  displayOverridePrice,
  dueNowPriceInCents,
  isOfferUnavailable,
  showSaveCardCheckbox,
  saveCardDefaultValue,
  isOfferFree,
  submitForm,
  handleHideModal,
  siteUserSignedIn,
  taxLoading,
  isCouponLoading,
  couponError,
  aboveQuantityLimit,
}) {
  const {
    getValues,
    setValue,
    watch,
    trigger,
    clearErrors,
    formState: { isValid, errors },
  } = useFormContext();
  const { t } = useTranslation();

  const {
    offer,
    price,
    site,
    nameRequired,
    phoneRequired,
    addressRequired,
    collectTaxId,
    priceOverrideRequired,
    member,
    stripe,
    offerPurchase,
  } = getValues();
  const { serviceAgreementRequired, customFields, isCustomFieldsCompleted } = useCheckoutSettings();
  const paymentProvider = watch('paymentProvider');
  const isPaymentDetailComplete = watch('isPaymentDetailComplete');
  const isPaymentInProgress = watch('isPaymentInProgress');
  const priceOverride = watch('priceOverride');
  const useSavedCard = watch('useSavedCard');
  const serviceAgreementChecked = watch('serviceAgreementChecked');

  // These conditions need to match the `CheckoutStripeElements` conditions for rendering Stripe's
  // Elements component otherwise this will error.
  if (stripe) {
    // the conditionality of this won't change per render, so we can safely disable the rule
    // eslint-disable-next-line react-hooks/rules-of-hooks
    setValue('elements', useElements());
  }

  const breakpoints = useSageBreakpoint();

  const isLoggedIn = !!member;
  const alreadySubscribed = offerPurchase?.type === 'subscription';
  const shouldDisableForm =
    isOfferUnavailable || !!isPaymentInProgress || alreadySubscribed || aboveQuantityLimit;
  const validOfferPriceOverride =
    minorUnitAmount(priceOverride, price.exponent) >= Math.max(price.amount, 50);
  const isDoingFreePWYW =
    priceOverrideRequired && isOfferFree && (parseFloat(priceOverride) === 0 || !priceOverride);
  const requiresCardDetails =
    !(isOfferFree || isDoingFreePWYW) && stripe && paymentProvider !== 'external_paypal';
  const isReadyToSubmit =
    !shouldDisableForm &&
    isValid &&
    (customFields ? isCustomFieldsCompleted : true) &&
    (!requiresCardDetails || useSavedCard || isPaymentDetailComplete) &&
    (validOfferPriceOverride || !priceOverrideRequired || isDoingFreePWYW) &&
    (!serviceAgreementRequired || serviceAgreementChecked);
  const shouldDisplayPaymentDetails =
    (stripe && paymentProvider === 'external_paypal') ||
    (requiresCardDetails && !isDoingFreePWYW && !useSavedCard);
  const shouldDisplayPreviewTooltip = isReadyToSubmit && siteUserSignedIn;
  const disableSubmit = siteUserSignedIn || !isReadyToSubmit;
  const isRecurring = price.priceType === 'recurring';
  const providerAllowsSavingCard = paymentProvider === 'card';
  const offerPurchaseDate = offerPurchase?.date;
  const isQuantityEnabled = offer.checkoutQuantityEnabled;

  return (
    <>
      <Helmet>
        <html className="checkout-modal__active" lang="en" />
      </Helmet>
      <Modal
        className="checkout-modal sage-scoped"
        size="lg"
        active={active}
        onExit={handleHideModal}
        fullScreen={breakpoints?.sm}
      >
        {offerPurchaseDate && (
          <Banner
            active={true}
            text={t('form.already_purchased', {
              offer: offer.title,
              date: localizedDateWithYear(offerPurchaseDate),
            })}
            link={{
              href: '/library',
              name: t('form.take_me_there'),
              rel: 'no-opener',
            }}
          />
        )}
        <Modal.Header
          className="checkout-modal__header"
          customHeader={<span className="t-sage-body-small-med">{site.title}</span>}
          image={
            site.logoImageUrl && {
              src: site.logoImageUrl,
              alt: '',
            }
          }
          aside={
            <Button
              data-testid="close-modal-button"
              color={Button.COLORS.PRIMARY}
              onClick={handleHideModal}
              subtle={true}
              disabled={isPaymentInProgress}
              className="checkout-modal__close-btn"
            >
              <CloseIcon />
            </Button>
          }
        />
        {/* Prevent the enter key from submitting the form for now to avoid accidental purchases
        by a logged in member, especially when they hit Enter to apply a coupon */}
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <form
          onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
          onSubmit={submitForm}
          className="checkout-modal__body"
        >
          <div className="checkout-modal__form">
            <PriceDisplay offer={offer} displayPrice={displayPrice} layoutType="sm" />
            <>
              {priceOverrideRequired && (
                <PriceOverrideInput displayPrice={displayPrice} disabled={shouldDisableForm} />
              )}
            </>
            <Modal.Body>
              <fieldset className="checkout-modal__fieldset">
                <legend className={`checkout-modal__legend ${SageClassnames.TYPE.HEADING_5}`}>
                  {t('form.your_information')}
                </legend>
                <EmailInput
                  disabled={isLoggedIn || shouldDisableForm}
                  defaultValue={isLoggedIn ? member.email : ''}
                />
                {nameRequired && <NameInput disabled={shouldDisableForm} />}
                {addressRequired && <AddressInput disabled={shouldDisableForm} />}
                {phoneRequired && <PhoneInput disabled={shouldDisableForm} />}
                {collectTaxId && <TaxIdInput disabled={shouldDisableForm} />}
                <CustomFields disabled={shouldDisableForm} customFields={customFields} />
              </fieldset>
              {!isOfferFree && stripe && (
                <fieldset className="checkout-modal__fieldset">
                  <legend className={`checkout-modal__legend ${SageClassnames.TYPE.HEADING_5}`}>
                    {t('form.payment_method')}
                  </legend>
                  {member?.savedCardDetails && <SavedCard />}
                  {shouldDisplayPaymentDetails && <PaymentDetails disabled={shouldDisableForm} />}
                  {requiresCardDetails &&
                    showSaveCardCheckbox &&
                    !useSavedCard &&
                    providerAllowsSavingCard && (
                      <SaveCardCheckbox
                        disabled={shouldDisableForm}
                        saveCardDefaultValue={saveCardDefaultValue}
                      />
                    )}
                </fieldset>
              )}
              <div
                className="checkout-modal__summary checkout-modal__summary--mobile"
                data-display-layout="sm"
              >
                {isQuantityEnabled && <QuantitySelector />}
                <PriceSummary
                  key="price-summary-mobile"
                  displayPrice={displayPrice}
                  displayOverridePrice={displayOverridePrice}
                  dueNowPriceInCents={dueNowPriceInCents}
                  taxLoading={taxLoading}
                  isCouponLoading={isCouponLoading}
                  couponError={couponError}
                />
              </div>
              <div className="checkout-modal__cta">
                <OptInCheckbox disabled={shouldDisableForm} />
                <ServiceAgreement disabled={shouldDisableForm} />
                {shouldDisplayPreviewTooltip && (
                  <div className="site-user-tooltip">
                    <Tooltip.Element
                      className="site-user-tooltip"
                      content="Checkout is disabled while you're logged in as a Kajabi site admin"
                    />
                  </div>
                )}
                {paymentProvider === 'external_paypal' &&
                !isPaymentInProgress &&
                !alreadySubscribed &&
                !isOfferFree ? (
                  <PayPalButton
                    offerToken={offer.token}
                    isRecurring={isRecurring}
                    clientId={offer.paypalClientId}
                    merchantId={offer.paypalMerchantId}
                    onSubmit={submitForm}
                    disable={disableSubmit}
                  />
                ) : (
                  <PayButton
                    disableSubmit={disableSubmit}
                    isPaymentInProgress={!!isPaymentInProgress}
                    dueNowPriceInCents={dueNowPriceInCents}
                  />
                )}
                <PaymentSummaryText />
                {errors.payment && (
                  <div className="sage-form-field--error">
                    <div className="sage-input__message">{errors.payment.message}</div>
                  </div>
                )}
              </div>
            </Modal.Body>
          </div>
          <div className="checkout-modal__summary" data-display-layout="lg">
            <PriceDisplay offer={offer} displayPrice={displayPrice} layoutType="lg" />
            {isQuantityEnabled && <QuantitySelector />}
            <PriceSummary
              key="price-summary"
              displayPrice={displayPrice}
              displayOverridePrice={displayOverridePrice}
              dueNowPriceInCents={dueNowPriceInCents}
              taxLoading={taxLoading}
              isCouponLoading={isCouponLoading}
              couponError={couponError}
            />
          </div>
        </form>
        <Modal.Footer className="checkout-modal__footer">
          <SecureCheckout />
        </Modal.Footer>
      </Modal>
    </>
  );
}

PopupCheckoutModal.propTypes = {
  active: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  handleHideModal: PropTypes.func.isRequired,
  displayPrice: PropTypes.string.isRequired,
  displayOverridePrice: PropTypes.string,
  dueNowPriceInCents: PropTypes.number.isRequired,
  isOfferUnavailable: PropTypes.bool.isRequired,
  showSaveCardCheckbox: PropTypes.bool.isRequired,
  saveCardDefaultValue: PropTypes.bool.isRequired,
  isOfferFree: PropTypes.bool.isRequired,
  siteUserSignedIn: PropTypes.bool.isRequired,
  taxLoading: PropTypes.bool,
  isCouponLoading: PropTypes.bool,
  couponError: PropTypes.string,
  aboveQuantityLimit: PropTypes.bool,
};

PopupCheckoutModal.defaultProps = {
  displayOverridePrice: null,
  taxLoading: false,
  isCouponLoading: false,
  couponError: '',
  aboveQuantityLimit: false,
};

export default PopupCheckoutModal;
