import React, { useMemo } from 'react';
import { Badge, SageClassnames, Frame } from '@kajabi/sage-react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import SalesTax from 'apps/commerce/common/checkout/components/SalesTax';
import { localizedPriceFromMinorUnit } from '../util';
import Coupon from './Coupon';
import OfferListItem from './OfferListItem';
import OrderBump from './OrderBump';
import useOrderBump from '../hooks/useOrderBump';

export const PriceSummary = ({
  displayPrice,
  displayOverridePrice,
  dueNowPriceInCents,
  taxLoading,
  isCouponLoading,
  couponError,
}) => {
  const { t } = useTranslation();
  const { getValues, watch } = useFormContext();
  const isOrderBumpActive = watch('orderBumpActive');
  const coupon = watch('coupon');
  const quantity = watch('quantity');
  const { price, offer, orderBump } = getValues();
  const orderBumpObject = useOrderBump(orderBump, price.tax);
  const showOrderBump = orderBump && !coupon?.giftCoupon;
  const { priceType } = price;
  const isRecurring = priceType === 'recurring';

  const localizedDueNowPrice = useMemo(() => {
    if (dueNowPriceInCents == null) return '';
    if (dueNowPriceInCents === 0 && !isRecurring) return t('form.free');
    return localizedPriceFromMinorUnit(price.currency, dueNowPriceInCents, price.exponent);
  }, [dueNowPriceInCents, isRecurring, t, price.currency, price.exponent]);

  if (!price || !offer) return null;
  const showDueNow = !(price.priceStrategy === 'fixed' && price.amount === 0);
  const Divider = () => <div className="price-summary__divider" />;
  const DueNow = () => (
    <Frame
      direction={Frame.DIRECTIONS.HORIZONTAL}
      align={Frame.ALIGNMENTS.CENTER_SPREAD}
      className="price-detail"
    >
      <h6 className={SageClassnames.TYPE.HEADING_6}>{t('form.due_now')}</h6>
      <div>
        <Badge isInteractive={false} value={price.currency} />
        <span className={`${SageClassnames.TYPE.BODY_BOLD} sage-spacer-left-xs`}>
          {localizedDueNowPrice}
        </span>
      </div>
    </Frame>
  );
  const Heading = () => (
    <h4 className={`${SageClassnames.TYPE.HEADING_5} sage-spacer-bottom`}>
      {t('form.price_summary')}
    </h4>
  );

  return (
    <div className="price-summary">
      {showOrderBump && (
        <div className="sage-spacer-bottom-sm" data-display-layout="sm">
          <h5 className={`${SageClassnames.TYPE.BODY_SEMI}`}>{t('form.add_to_purchase')}</h5>
          <OrderBump src={orderBumpObject} />
        </div>
      )}
      <Heading />
      <>
        <Frame gap={Frame.GAPS.SM}>
          <OfferListItem
            displayPrice={displayPrice}
            displayOverridePrice={displayOverridePrice}
            price={price}
            offerTitle={offer.title}
            checkoutQuantityEnabled={offer.checkoutQuantityEnabled}
            imageSrc={offer.imageUrl}
            quantity={quantity}
          />
          {isOrderBumpActive && (
            <OfferListItem
              displayPrice={orderBumpObject.displayPrice}
              price={orderBumpObject.price}
              offerTitle={orderBumpObject.title}
              imageSrc={orderBumpObject.imageUrl}
            />
          )}
        </Frame>
        <div className="price-summary__details">
          {offer.couponAllowed && (
            <Coupon isCouponLoading={isCouponLoading} couponError={couponError} />
          )}
          {offer.taxable && <SalesTax taxLoading={taxLoading} />}
          {showDueNow && (
            <>
              <Divider />
              <DueNow />
            </>
          )}
        </div>
        {showOrderBump && (
          <div className="sage-spacer-top-lg" data-display-layout="lg">
            <h5 className={`${SageClassnames.TYPE.BODY_SEMI}`}>{t('form.add_to_purchase')}</h5>
            <OrderBump src={orderBumpObject} />
          </div>
        )}
      </>
    </div>
  );
};

PriceSummary.propTypes = {
  displayPrice: PropTypes.string.isRequired,
  displayOverridePrice: PropTypes.string,
  dueNowPriceInCents: PropTypes.number.isRequired,
  taxLoading: PropTypes.bool.isRequired,
  isCouponLoading: PropTypes.bool,
  couponError: PropTypes.string,
};

PriceSummary.defaultProps = {
  displayOverridePrice: null,
  isCouponLoading: false,
  couponError: '',
};
